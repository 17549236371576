<template>
  <div class="notice container">
    <section class="detail">
      <article>
        <div class="title">
          <p>
            {{ category == "EVENT" ? "이벤트" : category == "NOTICE" ? "공지사항" : "" }}
          </p>
          <h4>{{ title }}</h4>
          <span>{{ moment(createdAt).format("YYYY-MM-DD") }}</span>
        </div>
        <div class="content">
          <pre>{{ content }} </pre>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { fetchNoticeDetail } from "@/api/index";
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
      noticeId: "",
      title: "",
      content: "",
      category: "",
      createdAt: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "공지사항");
  },
  mounted() {
    if (this.$route.query.id) {
      this.noticeId = this.$route.query.id;
      this.getNoticeDetail();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getNoticeDetail() {
      fetchNoticeDetail(this.noticeId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.content = res.data.data.content;
          this.createdAt = res.data.data.createdAt;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
